import React, { Component } from "react";
import "../App.css";
import axios from "axios";

class AnimationImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      flagFileDownload: false,
      fileName: "",
      code: "",
      message: "",
      videoStatus: "",
      programStatus: "",
      flagLoadingVideoLink: false,
      isImage: false,
      isDemoVideo: false,
      isResources: false,
      showWhat: "code",
    };
  }
  hideMessage = () => {
    window.setTimeout(() => {
      this.setState({
        message: "",
      });
    }, 7000);
  };
  getContentToDisplay = () => {
    let { program } = this.props;
    let s = "";
    s = program.programNumber + ". " + program.description + "  ";
    return s;
  };

  handleShowContentClick = () => {
    let { selectedMenu } = this.props;

    if (selectedMenu.name == "video" || selectedMenu.name == "note") {
      this.handlePlayVideoClick();
      return;
    } else {
      this.handleShowProgramCodeClick();
      return;
    }
  };
  // get link for the unlisted videos, it is --- made from server side

  handleShowProgramCodeClick = () => {
    // Check here only, whether  student has taken admission
    //  for this course or not, or is it a Demo
    let { selectedSubjectName } = this.props;
    // let { selectedSubjectAvailableForDemo } = this.props;
    let { selectedSubjectAvailableFor } = this.props;
    let { selectedSubjectFees } = this.props;
    // selectedSubjectAvailableForDemo = Boolean(selectedSubjectAvailableForDemo);
    let message1 =
      "Sorry… This content is available only for registered students. Fees of this course is only Rs. " +
      selectedSubjectFees +
      ". Want to join this course (online/offline)? Contact us at rjacpune@gmail.com or WhatsApp at 9422035020";
    let message2 =
      `Currently, content of following courses can be viewed.
      1. Programming (C, C++, Python)
      2. OOP using C++ and Python
      3. Data Structures
      Want to join the course ` +
      selectedSubjectName +
      `
      ? Contact us at rjacpune@gmail.com or WhatsApp at 9422035020`;
    let message3 =
      "Join this course - " + selectedSubjectName + " (online/offline)";
    let { visitorInfo } = this.props;
    let { selectedChapterNumber } = this.props;
    let { program } = this.props;
    let availableForDemo = Boolean(program.availableForDemo);
    let message;
    let requestUrl;
    // subject available for Demo
    if (selectedSubjectAvailableFor == "All") {
      if (availableForDemo) {
        message = "";
        requestUrl = "/files/downloadDemoFile/";
      } else if (visitorInfo.status == "Guest") {
        // Guest
        message = message1;
      }
      // not guest, so a registered student
      else if (visitorInfo.admissionStatus == "Admitted") {
        // check chapter-limit
        if (selectedChapterNumber > visitorInfo.chapterLimit) {
          // message = "Please request Admin to access data of this chapter";
          message =
            "You have not completed chapter no. " +
            visitorInfo.chapterLimit +
            " yet.";
        } else {
          message = "";
          requestUrl = "/files/downloadFromFile/";
        }
      } else if (
        (visitorInfo.status =
          "Registered" && visitorInfo.admissionStatus == "Guest")
      ) {
        message = message3;
      }
    } //end of "All" (Programming, OOP, DS)
    else {
      // subject NOT available for Demo
      if (visitorInfo.status == "Guest") {
        // Guest
        message = message2;
      } else {
        requestUrl = "/files/downloadFromFile/";
      }
    }
    if (message) {
      this.setState({
        message: message,
      });
      this.hideMessage();
      return;
    }

    this.props.onShowCodeClick(program);
    let { filePrefixWOExtension } = this.props;
    let fileName = filePrefixWOExtension + program.programNumber;
    // Program name is not saved
    // It is "filePrefix"+program number
    // E.g. c1p1
    // Content-Type': 'application/pdf'
    //Now check is it demo file
    this.setState({
      flagLoadingActualContent: true,
      flagLoading: true,
    });
    axios
      .get(window.routerPrefix + requestUrl + fileName)
      .then((response) => {
        this.setState({
          flagFileDownload: true, // downloaded successfully
        });
        if (response.data != "nothing") {
          this.setState({
            code: response.data,
          });
        } else {
          this.setState({
            code: "",
          });
        }
        this.setState({
          message: "",
        });
        let fileName1 = fileName;
        fileName = response.headers["content-disposition"].split('"')[1];
        this.setState({
          fileName: fileName,
        });
        // Now check whether some image / video is there related with this program
        axios
          .get(window.routerPrefix + "/files/programResourcesInfo/" + fileName1)
          .then((res) => {
            // console.log("aai..." + res.data);
            let isImage, isDemoVideo, isResources;
            let data = res.data;
            let s = data[0];
            isImage = Boolean(s);
            s = data[1];
            isResources = Boolean(s);
            s = data[2];
            isDemoVideo = Boolean(s);
            // console.log(isImage + " " + isResources + " " + isDemoVideo);
            let demoVideoLink;
            if (isDemoVideo) {
              demoVideoLink = data[2];
            } else {
              demoVideoLink = "---";
            }
            this.setState({
              isImage: isImage,
              isDemoVideo: isDemoVideo,
              isResources: isResources,
              demoVideoLink: demoVideoLink,
              flagLoadingActualContent: false,
            });
          })
          .catch((err) => {
            this.setState({
              error: err,
              message: "Something went wrong",
              flagLoadingActualContent: false,
            }); // error is set
            this.hideMessage();
          });
      })
      .catch((err) => {
        this.setState({
          error: err,
          flagLoadingActualContent: false,
          message: "Something went wrong",
          flagLoading: false,
        }); // error is set
        this.hideMessage();
      });
  };
  handleCopyToClipboard = () => {
    navigator.clipboard.writeText(this.state.code);
  };
  handleShowImageClick = () => {
    this.setState({
      showWhat: "image",
    });
  };
  handleShowDemoVideoClick = () => {
    this.setState({
      showWhat: "demoVideo",
    });
  };
  handleDownloadFilesClick = () => {
    this.setState({
      showWhat: "resources",
    });
  };
  handleShowCodeClick = () => {
    this.setState({
      showWhat: "code",
    });
  };
  handleDownloadYesClick = () => {
    // pdf download
    let requestUrl =
      "/general/codePdf/" +
      this.props.selectedSubjectFilePrefix +
      this.state.selectedChapterNumber;
    this.setState({
      programList: [], // programlist should be empty
    });
    this.downloadPdfFile(requestUrl);
  };
  handleCodeDownloadClick = () => {
    let type = "text/plain";
    const url = window.URL.createObjectURL(
      new Blob([this.state.code], {
        type: type,
      })
    );
    let fileName = this.state.fileName;
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName);
    document.body.appendChild(link);
    link.click();
    link.remove(); // you need to remove that elelment which is created before.
  };
  showAvailability = () => {
    let availability = "";
    let { program } = this.props;
    let { visitorInfo } = this.props;
    let availableForDemo = Boolean(program.availableForDemo);
    let { selectedMenu } = this.props;
    if (selectedMenu.name == "video" || selectedMenu.name == "note") {
      if (window.hosting == "offline") {
        if (program.location == "YouTubePublic") {
          availability = "Available on YouTube";
        } else if (program.location == "YouTubeUnlisted") {
        } else if (program.location == "YouTubeUnlisted") {
        } else {
          availability = "";
        }
      } else if (window.hosting == "internet") {
        // video on internet
        if (program.location == "YouTubePublic") {
          if (visitorInfo.admissionStatus == "Guest") {
            // availability = "Preview";  // 22 Aug. 2024
            availability = "";
          } else if (
            // For Admitted (offline)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Offline"
          ) {
            availability = "Available";
          } else if (
            // For Admitted (offline)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Online"
          ) {
            availability = "";
          }
        } // end of YouTubePublic
        else if (program.location == "YouTubeUnlisted") {
          if (visitorInfo.admissionStatus == "Guest") {
            availability = "";
          } else if (
            // For Admitted (offline)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Offline"
          ) {
            if (selectedMenu.name == "note") {
              //notes
              availability = "Available";
            } else if (selectedMenu.name == "video") {
              // video
              availability = "";
            }
          } else if (
            // For Admitted (online)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Online"
          ) {
            availability = "";
          }
        } // end of YouTubeUnlisted
        else if (
          program.location != "YouTubePublic" &&
          program.location != "YouTubeUnlisted"
        ) {
          if (visitorInfo.admissionStatus == "Guest") {
            availability = "";
          } else if (
            // For Admitted (offline)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Offline"
          ) {
            availability = "";
          } else if (
            // For Admitted (online)
            visitorInfo.admissionStatus == "Admitted" &&
            visitorInfo.admissionType == "Online"
          ) {
            availability = "";
          }
        } // end of local (not YouTubePublic and not YouTubeUnlisted)
      } // end of video/note
    } else {
      // program, exercise, misc.
      if (window.hosting == "offline") {
        if (availableForDemo && visitorInfo.admissionStatus == "Guest") {
          availability = "Preview";
        } else {
          availability = "";
        }
      } else if (window.hosting == "internet") {
        if (availableForDemo && visitorInfo.admissionStatus == "Guest") {
          // availability = "Preview";
          availability = ""; // 22 Aug. 2024
        } else {
          availability = "";
        }
      }
    }
    return availability;
  };
  handlePlayVideoClick = () => {
    // Check here only, whether  student has taken admission
    //  for this course or not, or is it a Demo, or Guest
    let { visitorInfo } = this.props;
    let { selectedChapterNumber } = this.props;
    let { selectedSubjectFees } = this.props;

    let { program } = this.props;
    let { selectedMenu } = this.props;
    let message1 =
      "Sorry… This content is available only for registered students. Fees of this course is only Rs. " +
      selectedSubjectFees +
      ". Want to join this course (online/offline)? Contact us at rjacpune@gmail.com or WhatsApp at 9422035020";
    // let availableForDemo = Boolean(program.availableForDemo);
    let message;
    if (window.hosting == "internet") {
      if (program.location == "YouTubePublic") {
        // Available to all : Guest, Registerd, Registerd + Admitted, Guest_to_subject
        message = "";
      } //end of YouTubePublic
      else if (program.location == "YouTubeUnlisted") {
        if (visitorInfo.status == "Guest") {
          // For guest - Join this course message
          message = message1;
        } else if (
          // Registered, but not for this course (So, Guest for this course)
          visitorInfo.admissionStatus == "Guest"
        ) {
          message = "Join this course (Online/Offline) to access the videos";
        } else if (
          // For Admitted (offline)
          visitorInfo.admissionStatus == "Admitted" &&
          visitorInfo.admissionType == "Offline"
        ) {
          if (selectedMenu.name == "note") {
            //notes
            //check chapter-limit
            if (selectedChapterNumber > visitorInfo.chapterLimit) {
              message =
                "You have not completed chapter no. " +
                visitorInfo.chapterLimit +
                " yet.";
            } else {
              message = "";
            }
          } else {
            // video
            message = "This lecture is available in offline class-room.";
          }
        } else if (
          // For Admitted (Online)
          visitorInfo.admissionStatus == "Admitted" &&
          visitorInfo.admissionType == "Online"
        ) {
          //check chapter-limit
          if (selectedChapterNumber > visitorInfo.chapterLimit) {
            message =
              "You have not completed chapter no. " +
              visitorInfo.chapterLimit +
              " yet.";
          } else {
            message = "";
          }
        }
      } //end of YouTubeUnlisted
      else if (
        program.location != "YouTubePublic" &&
        program.location != "YouTubeUnlisted"
      ) {
        // Local videos
        if (visitorInfo.status == "Guest") {
          // For guest - Join this course message
          message = "Join the course (Online/Offline) to access the videos";
        } else if (
          // For Admitted (offline)
          visitorInfo.admissionStatus == "Admitted" &&
          visitorInfo.admissionType == "Offline"
        ) {
          message = "This lecture is available in offline class-room.";
        } else if (
          // For Admitted (Online)
          visitorInfo.admissionStatus == "Admitted" &&
          visitorInfo.admissionType == "Online"
        ) {
          //check chapter-limit
          if (selectedChapterNumber > visitorInfo.chapterLimit) {
            message =
              "You have not completed chapter number " +
              visitorInfo.chapterLimit +
              " yet.";
          } else {
            message = "";
          }
        }
      } //end of Neither "YouTubePublic" nor "YouTubeUnlisted"
    } //end of window.hosting==internet
    else if (window.hosting == "offline") {
      if (visitorInfo.admissionStatus == "Guest") {
        // Guest
        message = "Please login to access the content"; // chapterLimit crossed
      } else if (visitorInfo.admissionStatus == "Admitted") {
        if (selectedChapterNumber > visitorInfo.chapterLimit) {
          // message = "Please request Admin to access videos of this chapter";
          message =
            "You have not finished chapter number " +
            visitorInfo.chapterLimit +
            " yet.";
        } else {
          message = "";
        }
      }
    }
    if (message) {
      this.setState({
        message: message,
      });
      this.hideMessage();
      return;
    }
    if (program.link != "---") {
      this.props.onPlayVideoClick(program, "");
    } else {
      let { program } = this.props;
      this.setState({
        flagLoadingVideoLink: true,
      });
      axios
        .get(window.routerPrefix + "/video/videoById/" + program.programId)
        .then((res) => {
          let data = res.data;
          if (!data) {
            this.setState({
              message: "Timeout... Refresh and Login again",
            }); // error is set
            this.hideMessage();
          }
          this.setState({
            flagLoadingVideoLink: false,
          });
          this.props.onPlayVideoClick(program, data.link);
        })
        .catch((err) => {
          this.setState({
            error: err,
            message: "Something went wrong",
            flagLoadingVideoLink: false,
          }); // error is set
          this.hideMessage();
        });
    }
    // this.props.onShowCodeClick(program);
  };
  getDownloadLink = () => {
    let { filePrefixWOExtension } = this.props;
    let { program } = this.props;
    let serverResponse =
      window.routerPrefix +
      "/files/downloadVideoFromFile/" +
      (filePrefixWOExtension + "" + program.programNumber);
    return serverResponse;
  };
  getDownloadDemoVideoLink = () => {
    let { filePrefixWOExtension } = this.props;
    let { program } = this.props;
    let serverResponse =
      window.routerPrefix +
      "/files/downloadDemoVideoFromFile/" +
      (filePrefixWOExtension + "" + program.programNumber);
    return serverResponse;
  };
  getVideoLength = () => {
    let { program } = this.props;
    let min = 0,
      sec = 0;
    if (program.length) {
      min = Math.floor(program.length / 60);
      sec = program.length % 60;
      if (min < 10 && min > 0) {
        // its single digit
        min = "0" + min;
      }
      if (sec < 10 && sec > 0) {
        // its single digit
        sec = "0" + sec;
      }
    }
    return min + ":" + sec;
  };
  sh_Code = () => {
    let { program } = this.props;
    let { selectedMenu } = this.props;
    let { flagFileDownload } = this.state;
    if (
      program.flagActive &&
      flagFileDownload && // file downloaded successfully
      selectedMenu.name != "video" &&
      selectedMenu != "note"
    )
      return true;
    return false;
  };
  sh_OfflineVideo = () => {
    let { program } = this.props;
    let { selectedMenu } = this.props;
    if (
      (selectedMenu.name == "video" || selectedMenu.name == "note") &&
      program.flagActive &&
      window.hosting == "offline"
    )
      return true;
    return false;
  };
  sh_YoutubeVideo = () => {
    let { program } = this.props;
    let { selectedMenu } = this.props;
    let { message } = this.state;
    let { flagLoadingVideoLink } = this.state;

    if (
      (selectedMenu.name == "video" || selectedMenu.name == "note") &&
      program.flagActive &&
      window.hosting == "internet" &&
      !message &&
      !flagLoadingVideoLink
    )
      return true;
    return false;
  };
  // handleDownloadResourcesFileClick
  handleDownloadResourcesFileClick = () => {
    // pdf download
    let { filePrefixWOExtension } = this.props;
    let { program } = this.props;
    let fileName = filePrefixWOExtension + program.programNumber;
    let requestUrl = "/files/resources/" + fileName;
    this.setState({
      flagLoadingResourcesFile: true,
    });
    axios
      .get(window.routerPrefix + requestUrl, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      })
      .then((res1) => {
        let type = "application/pdf";
        const url = window.URL.createObjectURL(
          new Blob([res1.data], {
            type: type,
          })
        );
        let fileName = res1.headers["content-disposition"].split('"')[1];
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
        link.remove();
        this.setState({
          flagLoadingResourcesFile: false,
        });
        this.setState({
          message: "File downloaded successfully!",
        });
        this.hideMessage();
        this.unselectMenu();
      })
      .catch((err) => {
        this.setState({
          error: err,
          message: "Something went wrong",
          flagLoadingResourcesFile: false,
        }); // error is set
        this.hideMessage();
      });
  };
  handlePresentationImageClick = () => {
    let { selectedSubjectFilePrefix } = this.props;
    let { selectedChapterNumber } = this.props;
    let { imageNumber } = this.props;
    let { folderNumber } = this.props;
    let image_info =
      selectedSubjectFilePrefix +
      "_" +
      selectedChapterNumber +
      "_" +
      folderNumber +
      "_" +
      imageNumber;
    axios
      .post(
        window.routerPrefix + "/files/presentation",
        // { fileName: "d" + imageNumber },
        { fileName: image_info },
        {
          headers: {
            accept: "application/json",
            "Accept-Language": "en-US,en;q=0.8",
          },
        }
      )
      .then((res) => {
        this.setState({
          flagLoading: false,
          response: res.data,
        });
        this.setState({
          flagLoading: false,
        });
      })
      .catch((err) => {
        console.log("error!");
        this.setState({
          flagLoading: false,
        });
      });
    this.props.onPresentationImageClick(imageNumber);
  };
  render() {
    let { message } = this.state;
    let { selectedSubjectFilePrefix } = this.props;
    let { selectedChapterNumber } = this.props;
    let { imageNumber } = this.props;
    let { folderNumber } = this.props;
    let { status } = this.props;
    let { show } = this.props;
    

    let image_info =
      selectedSubjectFilePrefix +
      "_" +
      selectedChapterNumber +
      "_" +
      folderNumber +
      "_" +
      imageNumber;
    ///e.g  cj_1_1_13
    return (
      <>
        {/* row starts - program-details */}
        {show && <div className="col-12 col-md-4">{imageNumber}</div>}
        {show && (
          <div
            className={
              "col-12 col-md-4 " + (status ? "presentation-image" : "")
            }
          >
            <img
              className="img-fluid"
              src={
                "api/files/downloadTeachingContentAnimationImageFile/" +
                image_info
              }
              onClick={this.handlePresentationImageClick}
            />
          </div>
        )}
        {/* row ends  - program-details */}
        {/* Below is for videos */}
        {message && <div className="error-text text-left">{message}</div>}
      </>
    );
  }
}
export default AnimationImage;
